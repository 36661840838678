<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
    >
      <div>
        <router-link to="/">
          Aller à la page d'accueil
        </router-link>
      </div>
      <div>
        <router-link to="/shopping-lists">
          Listes de courses
        </router-link>
      </div>
    </v-navigation-drawer>

    <v-app-bar>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      />

      <v-spacer />
      <div v-if="user">
        <span>Bonjour {{ user.getDisplayName() }}</span>
        <v-btn
          color="primary"
          variant="text"
          @click="logout()"
        >
          Déconnexion
        </v-btn>
      </div>
      <v-btn
        v-else
        color="primary"
        variant="contained-text"
        @click="login()"
      >
        Connexion
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { ref } from "vue"

import { useUser } from "@/composables/user";

const { user, login, logout } = useUser()

const drawer = ref(false)
</script>
