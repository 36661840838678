<script setup>
import { useRouter } from "vue-router";

import { useShoppingLists } from "@/composables/shopping.lists";
import ShoppingListIndex from "@/components/ShoppingListIndex";

const router = useRouter()

function onShoppingListClick(shoppingListIndex) {
  router.push(`/shopping-lists/${shoppingListIndex}`)
}

const { shoppingLists } = useShoppingLists()
</script>

<template>
  <h1>Listes de courses</h1>
  <v-btn
    color="primary"
    variant="contained-text"
    @click="shoppingLists.createShoppingList('foo')"
  >
    Créer une liste de courses
  </v-btn>
  <div
    v-for="(shoppingList, index) in shoppingLists.getShoppingLists()"
    :key="shoppingList.name"
  >
    <ShoppingListIndex
      :shopping-list="shoppingList"
      @remove="shoppingLists.remove(shoppingList)"
      @click="onShoppingListClick(index)"
    />
  </div>
</template>

<style scoped>

</style>
