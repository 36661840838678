export class ShoppingLists {
    constructor(localShoppingListsRepository) {
        this.localShoppingListsRepository = localShoppingListsRepository
        const loadedShoppingList = this.localShoppingListsRepository.load(this)
        this.shoppingLists = loadedShoppingList ? loadedShoppingList : []
        this.onlineShoppingListsRepository = null
    }

    getShoppingLists() {
        return this.shoppingLists
    }

    getShoppingList(shoppingListIndex) {
        return this.shoppingLists[shoppingListIndex]
    }

    save() {
        this.localShoppingListsRepository.save(this.shoppingLists)
    }

    createShoppingList(name) {
        const shoppingList = new ShoppingList(this.localShoppingListsRepository, name);
        this.shoppingLists.push(shoppingList)
        this.save()
        return shoppingList
    }

    remove(index) {
        if (index >= this.shoppingLists.length) {
            throw new Error('Failed to remove shopping list from shopping lists.')
        }
        this.shoppingLists.splice(index, 1)
        this.save()
    }

    setOnlineShoppingListsRepository(shoppingListsOnlineRepository) {
        this.onlineShoppingListsRepository = shoppingListsOnlineRepository
        this.synchronizeOnlineShoppingLists()
    }

    synchronizeOnlineShoppingLists() {
        if (!this.onlineShoppingListsRepository) {
            throw new Error('No online shopping list repository.')
        }
        const shoppingLists = this.onlineShoppingListsRepository.load()
        this.shoppingLists = shoppingLists
    }

    removeOnlineShoppingListsRepository() {
        this.onlineShoppingListsRepository = null
    }
}

export class ShoppingList {

    constructor(save, name, items = []) {
        this.save = save
        this.name = name
        this.items = items
    }

    getItems() {
        return this.items
    }

    addItem(item) {
        this.items.push(item)
        this.save()
    }

}
