if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
            .catch((error) => console.error('service worker not registered', error))
}

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

import './plugins/firebase'

import router from './plugins/router'

createApp(App)
        .use(vuetify)
        .use(router.router)
        .mount('#app')
