import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyCDTqvWfS0qkKuThfGsx0w82xS3Q8BEJR4",
    authDomain: "aconume.firebaseapp.com",
    projectId: "aconume",
    storageBucket: "aconume.appspot.com",
    messagingSenderId: "744119428280",
    appId: "1:744119428280:web:8a26bbe6f12990fa45004d",
    measurementId: "G-1T1DP1J652"
};

const app = initializeApp(firebaseConfig)

export default {
    app
}
