// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
        defaultTheme: 'aconumeTheme',
        themes: {
            aconumeTheme: {
                dark: false,
                colors: {
                    primary: '#6BE29D',
                }
            },
        }
    }
})
