<script setup>
import { useRoute, useRouter } from 'vue-router'

import { useShoppingLists } from "@/composables/shopping.lists";


const route = useRoute()
const router = useRouter()
const shoppingListId = parseInt(route.params.shoppingListId)

const { getShoppingList, removeShoppingList } = useShoppingLists()
const shoppingList = getShoppingList(shoppingListId)

const onDeleteShoppingList = function () {
  removeShoppingList(shoppingListId)
  router.replace('/shopping-lists')
}

const onAddItem = function(itemName) {
  shoppingList.addItem(itemName)
}

</script>

<template>
  <h1>{{ shoppingList.name }}</h1>
  <v-btn @click="onDeleteShoppingList">
    Effacer cette liste de course.
  </v-btn>
  <v-btn @click="onAddItem('bar')">
    Rajouter un article
  </v-btn>
  <div
    v-for="(item, itemIndex) in shoppingList.items"
    :key="itemIndex"
  >
    {{ item }}
  </div>
</template>

<style scoped>

</style>
