import Home from "@/components/Home";
import ShoppingLists from "@/components/ShoppingLists";
import ShoppingList from "@/components/ShoppingList";

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/', component: Home },
    { path: '/shopping-lists', component: ShoppingLists },
    { path: '/shopping-lists/:shoppingListId', component: ShoppingList },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

export default {
    router
}
