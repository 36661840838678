import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

class User {
    constructor(firebaseUser) {
        this.firebaseUser = firebaseUser
    }

    getDisplayName() {
        return this.firebaseUser.displayName
    }

    async logout() {
        const auth = getAuth()
        try {
            await signOut(auth)
        } catch (error) {
            console.error(error)
        }
    }
}

export async function login() {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    try {
        const result = await signInWithPopup(auth, provider)
        let user = new User(result.user);
        return user
    } catch (error) {
        console.error(error)
    }
}
