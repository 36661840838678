import { ref } from "vue";

import * as User from '@/firebase/user'

export function useUser() {
    const user = ref(null)

    async function login() {
        try {
            user.value = await User.login()
        } catch (error) {
            console.error(error)
        }
    }

    async function logout() {
        try {
            await user.value.logout()
            user.value = null
        } catch (error) {
            console.error(error)
        }
    }

    return {
        user,
        login,
        logout
    }
}
