import { ref } from "vue";

import { ShoppingLists } from "@/core/shopping.lists";

import { ShoppingListsLocalRepository } from "@/webstorage/ShoppingListsLocalRepository";

export function useShoppingLists() {
    const shoppingListsLocalRepository = new ShoppingListsLocalRepository()
    const shoppingLists = ref(new ShoppingLists(shoppingListsLocalRepository))

    function getShoppingList(shoppingListIndex) {
        return shoppingLists.value.getShoppingList(shoppingListIndex)
    }

    function removeShoppingList(shoppingListIndex) {
        return shoppingLists.value.remove(shoppingListIndex)
    }

    return {
        shoppingLists,
        getShoppingList,
        removeShoppingList
    }
}
