import { ShoppingList } from "@/core/shopping.lists";

export class ShoppingListsLocalRepository {
    load(shoppingLists) {
        const storeShoppingLists = window.localStorage.getItem('shoppingLists');
        if (storeShoppingLists) {
            return JSON.parse(storeShoppingLists).map(shoppingList => new ShoppingList(() => shoppingLists.save(), shoppingList.name, shoppingList.items))
        } else {
            return null
        }
    }

    save(shoppingLists) {
        window.localStorage.setItem('shoppingLists', JSON.stringify(shoppingLists.map(shoppingList => ({
            name: shoppingList.name,
            items: shoppingList.items
        }))));
    }
}
